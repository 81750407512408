export const ipfsCids = {
  walletOtpPfp: 'bafybeie7nvrlwxqkmvj6e3mse5qdvmsozmghccqd7fdxtck6dbhcxt3le4',
  apecoin: 'bafkreifmjgv2lmdqxhp6cepq5kmb2i6ozk5c2iabu6nzrsvt7ggwn5wntq',
  ens: 'bafkreibzm2ul2id57a4efek3eenwzvnmhiq2boxluqdj6p6dotwb6vt7le',
  lit: 'bafkreiauwzflqsapvvmermw42ndjzw23czwbfoiqiu42p5wkokjxiquc3e',
  polybase: 'bafkreie2k7r3okk3ms74oefnjzmgtrz2ue3faihjga7xnw3pemoedt5q44',
  nftstorage: 'bafkreihkkfdbauyxvfhj2bcnt2ljaff6aixwo23qxbhbpf67o23p2rukxe',
  ipfs: 'bafkreihoekcoiqo2pq2kjb2j2otsr6mzwjidwcyig4vwdmwtpfkyizfili',
  saturn: 'bafkreif7yyl5khpbsh5dwzb5nmtfp2bnwneh36aytcirgvq65mty5ewim4',
  fleek: 'bafkreihswpk56oln6vfwapzdthzyjy7w7l4vpim6fmqira4lfnqfs56jne',
};

export const test2FAData2 = [
  {
    service: 'Binance',
    account: '@2fai',
    secret: 'j22h ni4e cd4o hqrx fka7 7uye wf2d xh77',
  },
  {
    service: 'Facebook',
    account: '@2fai',
    secret: 'oxmh oqj3 f3p3 5lca iarf dpqv rhc6 yn12',
  },
  {
    service: 'Bybit',
    account: '@2fai',
    secret: 'abcd ni4e cd4o hqrx fka7 7uye wf2d ffff',
  },
  {
    service: 'OKX',
    account: '@2fai',
    secret: 'oxmh oqj3 f3p3 5lca iarf dpqv rhc6 yn12',
  },
];

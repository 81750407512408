export const test2FAData = [
  {
    service: 'Coinbase',
    account: '@2fai',
    secret: 'tcwb oqj3 f3p3 5lca iarf dpqv rhc6 5iwt',
  },
  {
    service: 'Google',
    account: '@2fai',
    secret: 'j22h ni4e cd4o hqrx fka7 7uye wf2d xh77',
  },
  {
    service: 'Instagram',
    account: '@2fai',
    secret: 'tcwb oqj3 f3p3 5lca iarf dpqv rhc6 5iwt',
  },
  {
    service: 'Twitter',
    account: '@2fai',
    secret: 'abcd ni4e cd4o hqrx fka7 7uye wf2d ffff',
  },
];
